<script>
  import { onMount } from "svelte";
  import { spring } from "svelte/motion";

  export let index;
  export let color;
  export let title;
  export let path;
  export let radius;
  export let icon = undefined;
  export let x;
  export let y;

  $: resolvedIndex = index + 1;
  $: range = 1 + 0.5 * resolvedIndex;

  let springCoords = spring({ x, y });
  $: springCoords.set({ x, y });

  let isPressed = false;
  let pressScale = spring(1, {});
  $: pressScale.set(isPressed ? 1 - 10 / (radius * 2) : 1);

  let entryScale = spring(0);

  function handlePressDown() {
    isPressed = true;
  }
  function handlePressUp() {
    isPressed = false;
  }
  onMount(async () => {
    entryScale.set(1);
  });
</script>

<style>
  .root {
    display: block;
    position: absolute;
    top: calc(-1 * var(--radius));
    left: calc(-1 * var(--radius));
    width: calc(var(--radius) * 2);
    height: calc(var(--radius) * 2);
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    touch-action: none;
    transform: translate(var(--left), var(--top)) scale(var(--press-scale))
      scale(var(--entry-scale));
  }
  .container {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--color);
    overflow: hidden;
  }
  .inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(calc(1 / var(--press-scale)));
  }
  .content-container {
    display: block;
    font-size: var(--circle-font-size);
    font-weight: var(--circle-font-weight);
    -webkit-user-select: none;
    user-select: none;
  }
  .icon {
    position: relative;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 30px;
    color: white;
  }
</style>

<a
  class="root"
  href={path}
  rel="noopener noreferrer"
  on:mousedown={handlePressDown}
  on:mouseup={handlePressUp}
  style=" --radius: {radius}px; --left: {$springCoords.x}px; --top: {$springCoords.y}px;
  --press-scale: {$pressScale}; --entry-scale: {$entryScale}; --color: {color};">
  <div class="container">
    <div class="inner-container">
      <div class="content-container">
        {#if icon != null}
          <div class="icon">
            <svelte:component this={icon} />
          </div>
        {:else}
          <span>{title}</span>
        {/if}
      </div>
    </div>
  </div>
</a>
