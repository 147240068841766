<script lang="typescript">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";

  import avatarSrc from "./assets/extracted-avatar-cropped-squooshed.png";

  let containerHeight: number;
  $: circleSize = containerHeight * 0.65;

  let avatarLoaded = false;
  onMount(async () => {
    const image = new Image();
    image.src = avatarSrc;
    if (image.complete) {
      avatarLoaded = true;
      return;
    }
    if (typeof HTMLImageElement.prototype.decode === "function") {
      await image.decode();
    } else {
      await new Promise(resolve => {
        image.onload = resolve;
      });
    }
    avatarLoaded = true;
  });
</script>

<style>
  .root {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  .circle-container {
    position: relative;
    width: var(--circle-size);
    height: 100%;
    border-bottom-left-radius: calc(var(--circle-size) / 2);
    border-bottom-right-radius: calc(var(--circle-size) / 2);
    overflow: hidden;
    transform-style: preserve-3d;
    will-change: auto;
  }
  .circle-background {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    background-color: #90caf9;
  }
  .avatar-img {
    position: relative;
    display: block;
    object-fit: contain;
    object-position: 50% bottom;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 560px) {
    .root {
      justify-content: center;
    }
  }
</style>

<div
  class="root"
  bind:clientHeight={containerHeight}
  style="--circle-size: {circleSize}px;">
  <div class="circle-container">
    <div aria-hidden="true" class="circle-background" />
    {#if avatarLoaded}
      <img
        transition:fade
        alt="A fancy photo of me"
        class="avatar-img"
        src={avatarSrc}
        width="605"
        height="1000" />
    {/if}
  </div>
</div>
