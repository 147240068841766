<script context="module">
  import CirclePacker from "./utils/circlePacker.js";
  import {
    CIRCLE_FONT_WEIGHT,
    CIRCLE_FONT_SIZE,
    CIRCLE_FONT_FAMILY,
    CIRCLE_PADDING
  } from "./config";

  const MEASURE_FONT_STYLE = [
    CIRCLE_FONT_WEIGHT, // font weight
    `${CIRCLE_FONT_SIZE}px`, // font size
    CIRCLE_FONT_FAMILY // font family
  ].join(" ");

  let __measurementContext = null;
  function getMeasurementContext() {
    if (__measurementContext === null) {
      const canvas = document.createElement("canvas");
      canvas.id = "text-measure";
      canvas.style.position = "absolute";
      canvas.style.visibility = "hidden";
      canvas.style.pointerEvents = "none";
      canvas.style.top = `${-Number.MAX_SAFE_INTEGER}px`;
      canvas.style.left = `${-Number.MAX_SAFE_INTEGER}px`;
      document.documentElement && document.documentElement.appendChild(canvas);
      __measurementContext = canvas.getContext("2d");
    }
    return __measurementContext;
  }

  let __packer = null;
  function getPacker() {
    if (__packer === null) {
      let packer = new CirclePacker();
      packer.then(p => {
        __packer = p;
      });
      return { isPromise: true, packer };
    }
    return { isPromise: false, packer: __packer };
  }
</script>

<script>
  import { onMount, tick } from "svelte";
  import HomeButton from "./HomeButton.svelte";

  // PROPS
  export let items = [];
  export let width;
  export let height;

  // STATE
  let processedItems = [];
  let packer = null;

  function measureItem(item) {
    const context = getMeasurementContext();
    context.font = MEASURE_FONT_STYLE;
    const { width } = context.measureText(item.title);
    return width / 2 + CIRCLE_PADDING / 2;
  }

  function initializeItems(items) {
    return items.map(({ icon, ...item }) => {
      const radius = icon ? 40 : measureItem(item);
      return {
        ...item,
        radius,
        width: radius * 2,
        height: radius * 2
      };
    });
  }

  let sizedItems = initializeItems(items);

  function isThereWorkToDo(nextItems) {
    const prevItems = processedItems;
    if (!prevItems) return true;

    let diffAmt = 0;
    nextItems.forEach((nextItem, i) => {
      const prevItem = prevItems[i];
      if (!prevItem) return;
      diffAmt += Math.abs(nextItem.x - prevItem.x);
      diffAmt += Math.abs(nextItem.y - prevItem.y);
    });
    return diffAmt > 5;
  }

  let ticking = false;
  function requestTick() {
    if (!ticking) {
      ticking = true;
      setTimeout(updatePositions, 64);
    }
  }

  async function updatePositions() {
    const nextProcessedItems = await packer.update(4);

    nextProcessedItems.forEach((item, idx) => {
      const rawItem = items[idx];
      if (rawItem.icon != null) {
        item.icon = rawItem.icon;
      }
    });

    const hasWorkToDo = isThereWorkToDo(nextProcessedItems);
    processedItems = nextProcessedItems;
    await tick();
    ticking = false;
    if (hasWorkToDo) {
      requestTick();
    }
  }

  onMount(async () => {
    const { isPromise, packer: packerResult } = getPacker();
    if (isPromise) {
      packer = await packerResult;
      for (let i = 0; i < sizedItems.length; i++) {
        const item = sizedItems[i];
        setTimeout(() => {
          packer.addNode(item);
          requestTick();
        }, 175 * i + 1);
      }
      await packer.setDimensions(width, height);
      updatePositions();
    } else {
      packer = packerResult;
    }
  });

  // Keep client dimensions in sync with worker
  $: if (packer) {
    packer.setDimensions(width, height).then(() => requestTick());
  }
</script>

{#each processedItems as item, i (item.title)}
  <HomeButton
    index={i}
    color={item.color}
    darkColor={item.darkColor}
    title={item.title}
    icon={item.icon}
    path={item.path}
    radius={item.radius}
    x={item.x}
    y={item.y} />
{/each}
