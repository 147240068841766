<script lang="typescript">
  import Home from "./Home.svelte";

  import {
    CIRCLE_FONT_FAMILY,
    CIRCLE_FONT_SIZE,
    CIRCLE_FONT_WEIGHT,
    CIRCLE_SPACING
  } from "./config";
</script>

<style>
  main {
    width: 100%;
    height: 100%;
  }
</style>

<main
  style=" --circle-font-family: {CIRCLE_FONT_FAMILY}; --circle-font-size: {CIRCLE_FONT_SIZE}px;
  --circle-font-weight: {CIRCLE_FONT_WEIGHT}; --circle-spacing: {CIRCLE_SPACING};
  ">
  <Home />
</main>
