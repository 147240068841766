<script context="module">
  import FaTwitter from "svelte-icons/fa/FaTwitter.svelte";
  import FaSoundcloud from "svelte-icons/fa/FaSoundcloud.svelte";
  import FaLinkedinIn from "svelte-icons/fa/FaLinkedinIn.svelte";
  import FaGithub from "svelte-icons/fa/FaGithub.svelte";
  import FaMastodon from "svelte-icons/fa/FaMastodon.svelte";

  const HOME_BUTTONS = [
    {
      title: "Twitter",
      darkColor: "rgba(0,0,0,0)",
      path: "https://twitter.com/vincentriemer",
      color: "#1EA1F3",
      icon: FaTwitter
    },
    {
      title: "Mastadon",
      darkColor: "rgba(0,0,0,0)",
      path: "https://front-end.social/@vincentriemer",
      color: "rgb(99, 100, 255)",
      icon: FaMastodon
    },
    {
      title: "Github",
      darkColor: "rgba(0,0,0,0)",
      path: "https://github.com/vincentriemer",
      color: "RGBA(36, 41, 47, 1.00)",
      icon: FaGithub
    },
    {
      title: "LinkedIn",
      darkColor: "rgba(0,0,0,0)",
      path: "https://www.linkedin.com/in/vincentriemer/",
      color: "#01659B",
      icon: FaLinkedinIn
    },
    {
      title: "SoundCloud",
      darkColor: "rgba(0,0,0,0)",
      path: "https://soundcloud.com/vincent_jackson",
      color: "#FF5601",
      icon: FaSoundcloud
    },
  ];
</script>

<script>
  import { tick } from "svelte";
  import Avatar from "./Avatar.svelte";
  import HomeButtonGroup from "./HomeButtonGroup.svelte";

  let windowWidth;
  let windowHeight;

  $: containerWidth = windowWidth > 560 ? Math.min(windowWidth - 100, windowHeight - 20, 1000) : windowWidth - 10;
  $: containerHeight = windowWidth > 560 ? containerWidth * 0.8 : windowHeight * 0.75;

  let buttonContainerRef;
  let buttonContainerWidth;
  let buttonContainerHeight;

  // This is a workaround for the initial clientWidth/Height not being reported
  // in chrome ¯\_(ツ)_/¯
  $: (async () => {
    if (buttonContainerRef != null) {
      await tick();
      buttonContainerWidth = buttonContainerRef.clientWidth;
      buttonContainerHeight = buttonContainerRef.clientHeight;
    }
  })();

  $: buttonGroupWidth = Math.min(buttonContainerWidth, 400);
  $: buttonGroupHeight = buttonContainerHeight;
</script>

<style>
  .root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    position: relative;
    width: var(--container-width);
    height: var(--container-height);
  }
  .left-section-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50px;
    width: 50%;
    height: 100%;
    transform: translate(-5%, -5%);
    transform-style: preserve-3d;
  }
  .right-section-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow: visible;
  }

  .title {
    width: 100%;
    display: flex;
    flex-basis: 33%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .title > h1,
  .title > p {
    display: block;
    white-space: nowrap;
    text-align: right;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    padding-left: 4px;
    padding-right: 4px;
  }
  .title > h1 {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -1.1px;
  }
  .title > p {
    font-size: 28px;
    font-weight: normal;
    letter-spacing: -0.5px;
    opacity: 0.7;
  }
  .buttons-container {
    position: relative;
    flex: 1;
    width: 100%;
    margin-top: var(--circle-spacing);
    margin-left: var(--circle-spacing);
  }

  @media (max-width: 560px) {
    .left-section-container {
      width: 100%;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    .right-section-container {
      width: 100%;
      height: 100%;
      padding-right: 0;
    }
  }
</style>

<svelte:window bind:innerWidth={windowWidth} bind:innerHeight={windowHeight} />

<div
  class="root"
  style="--container-width: {containerWidth}px; --container-height: {containerHeight}px;">
  <div class="content">
    <div class="left-section-container">
      <Avatar />
    </div>
    <div class="right-section-container">
      <div class="title">
        <h1>Vincent Riemer</h1>
        <p>Frontend Developer</p>
      </div>
      <div
        class="buttons-container"
        bind:this={buttonContainerRef}
        bind:clientWidth={buttonContainerWidth}
        bind:clientHeight={buttonContainerHeight}>
        {#if buttonContainerWidth != null && buttonContainerHeight != null}
          <HomeButtonGroup
            items={HOME_BUTTONS}
            width={buttonGroupWidth}
            height={buttonGroupHeight} />
        {/if}
      </div>
    </div>
  </div>
</div>
